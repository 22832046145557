/**
 * Componente AnimatedCube que retorna os cubos animados da aplicação.
 *
 * @param {string} sectionId - id da sessão para ao entrar animar uma vez
 * @param {string} cssClass - classe css que deve ser escrita na sessão ou pagina que importa o coponente. (deve conter top e left ou right para posicionar o cubo)
 * @param {string} positionSide - lado em que o cubo sera rendirizado para o componente aplicar a animação correta
 * @param {string} cubeImg - nome da imagem dentro de /assets/images/cubes @default 'cube.png'
 */

import { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from '../../contexts/GlobalContext';
import styles from './AnimatedCube.module.css'
import getImage from '../../useFull/getImage'
import classNames from "classnames"

const AnimatedCube = ({ sectionId, cssClass, positionSide, cubeImg }) => {
    const cubeRef = useRef(null);
    const [heWasAnimated, setWeWasAnimated] = useState(false)
    const { state } = useContext(GlobalContext);
    useEffect(() => {
        if (state.activeSection === sectionId) {
            const timeoutForTrue = setTimeout(() => {
                setWeWasAnimated(true)
            }, 500);
            // Remove a animação após 1 segundo
            const timeoutForFalse = setTimeout(() => {
                setWeWasAnimated(false)
            }, 1000);

            // Limpa o timeout ao desmontar ou se a seção mudar
            return () => clearTimeout(timeoutForTrue, timeoutForFalse);
        }
    }, [state.activeSection, sectionId]);
    return (
        !cssClass ? <>
        </> : (
            <div
                ref={cubeRef}
                className={classNames(
                    ['bg-icon', styles.cube, cssClass, styles[positionSide]],
                    {[styles[`${positionSide}-animate`]]: state.activeSection === sectionId && heWasAnimated},
                )}
                style={{
                    backgroundImage: `url(${getImage('cubes', cubeImg || 'cube.png')})`
                }}
            >
            </div>

        )
    )
}
export default AnimatedCube