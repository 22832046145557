/**
 * Componente AnimatedServices que retorna o input animado da sessao home.
 *
 * @param {Array} itens - Array de strings com os intens para randomizar
 */

import { useEffect, useState } from 'react';
import styles from './AnimatedServices.module.css';

const AnimatedServices = ({ itens }) => {
  const [currentValue, setCurrentValue] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(Math.floor(Math.random() * itens.length));
  const [currentLetterIndex, setCurrentLetterIndex] = useState(0);
  const [width, setWidth] = useState('520px');
  const [speed, setSpeed] = useState(150); // Velocidade da animação
  const maxChars = 40; // Limite de caracteres

  // Função para obter um novo índice randômico diferente do atual
  const getRandomIndex = (excludeIndex) => {
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * itens.length);
    } while (randomIndex === excludeIndex);
    return randomIndex;
  };

  useEffect(() => {
    const handleTyping = () => {
      const fullText = itens[currentIndex];

      // Limita o texto a no máximo 30 caracteres
      const limitedText = fullText.slice(0, maxChars);

      if (isDeleting) {
        // Apagar o texto
        if (currentLetterIndex > 0) {
          setCurrentValue(prev => prev.slice(0, -1));
          setCurrentLetterIndex(prev => prev - 1);
        } else {
          setIsDeleting(false);
          setCurrentIndex(getRandomIndex(currentIndex)); // Seleciona o próximo índice randômico
        }
      } else {
        // Digitar o texto
        if (currentLetterIndex < limitedText.length) {
          setCurrentValue(prev => prev + limitedText[currentLetterIndex]);
          setCurrentLetterIndex(prev => prev + 1);
        } else {
          // Pausa antes de começar a apagar
          setTimeout(() => setIsDeleting(true), 2000);
        }
      }
    };

    const typingSpeed = isDeleting ? speed / 2 : speed;
    const timer = setTimeout(handleTyping, typingSpeed);

    return () => clearTimeout(timer);
  }, [currentValue, isDeleting, currentIndex, currentLetterIndex, itens, speed]);

  useEffect(() => {
    // Função que ajusta a largura do componente
    const handleResize = () => {
      if (window.innerWidth < 560) {
        setWidth(`${window.innerWidth - 60}px`); // Define o width para a largura atual da tela - 80px
      } else {
        setWidth('520px'); // Caso a largura seja maior, define como 520px
      }
    };

    // Executa a função ao carregar a página e adiciona um listener para resize
    handleResize();
    window.addEventListener('resize', handleResize);

    // Remove o listener ao desmontar o componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={styles['animation-services']} style={{width}}>
      <div className={styles['text-container']}>
        <span className='t2'>
          {currentValue}
        </span>

      </div>

      <div className={`bg-icon ${styles.search}`} />
    </div>
  );
}

export default AnimatedServices;
