/**
 * Componente CardWHY que retorna o card da sessão de Por que a perspective.
 *
 * @param {string} label - titulo
 * @param {string} value - descrição
 * @param {string} valueBackgroundColor - cor de background da descrição
 * @param {string} valueTextColor - cor da descrição
 * @param {string} labelBackgroundColor - cor de background do titulo
 * @param {string} labelTextColor - cor da descrição
 * 
 */

import classNames from 'classnames';
import styles from './CardWHY.module.css';

const CardWHY = ({ label, value, valueBackgroundColor, valueTextColor, labelBackgroundColor, labelTextColor }) => {


    return (
        <>
            <div
                className={styles['card-container']} style={{
                    ...(valueBackgroundColor ? { backgroundColor: valueBackgroundColor } : {}),
                    ...(valueTextColor ? { color: valueTextColor } : {})
                }}>
                {label && <span
                style={{
                    ...(labelBackgroundColor ? { backgroundColor: labelBackgroundColor } : {}),
                    ...(labelTextColor ? { color: labelTextColor } : {}) 
                }}
                    className={classNames([styles.title, 't4'])}
                >
                    {label}
                </span>}
                <span dangerouslySetInnerHTML={{ __html: value }} className='t4'></span>
            </div>
        </>

    );
};

export default CardWHY;
