import { GlobalContext } from '../../contexts/GlobalContext'
import { useContext, useEffect, useState } from "react";
import styles from './Solutions.module.css';

import Button from '../../components/Button/Button';
import PageTitle from '../../components/PageTitle/PageTitle';
import AnimatedCube from '../../components/AnimatedCube/AnimatedCube';

const SolutionItem = ({ value, label }) => {
    const [removeLineBreak, setRemoveLineBreak] = useState(false);
    useEffect(() => {
        // Função que ajusta a largura do componente
        const handleResize = () => {
            if (window.innerWidth < 810) {
                setRemoveLineBreak(true);
            } else {
                setRemoveLineBreak(false);
            }
        };

        // Executa a função ao carregar a página e adiciona um listener para resize
        handleResize();
        window.addEventListener('resize', handleResize);

        // Remove o listener ao desmontar o componente
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div
            className={styles['solution-item']}
        >
            <span className={`${styles.label} t2`} dangerouslySetInnerHTML={{ __html: removeLineBreak ? label.replace(/<br\s*\/?>/gi, '') : label }}></span>
            <span className={`${styles.value} t4`}>{value}</span>
        </div>
    );
}

const Solutions = () => {
    const { state } = useContext(GlobalContext);

    const language = state.language || 'pt-br';
    const {
        title,
        subtitle,
        btnSection,
        services
    } = require(`../../assets/languages/${language}/solutions.json`);



    return (
        <>
            <section className={`${styles['container-home']} mg-bot-100`} id='solutions'>
                <AnimatedCube sectionId={'#solutions'} cssClass={styles.cube} cubeImg={'cube-solutions-1.png'} positionSide={'left'} />
                <AnimatedCube sectionId={'#solutions'} cssClass={styles.cube2} cubeImg={'cube-solutions-2.png'} positionSide={'right'} />
                <div className={`${styles.content} mg-top-100`} data-aos="fade-left" data-aos-duration="800">
                    <PageTitle title={title} width={'350px'} subtitle={subtitle} backgroundColor={'var(--primary)'} color={'var(--black)'} isTag={true} />



                </div>
                <div className='mg-bot-50' data-aos="fade-right" data-aos-duration="800">
                    {services.map((d, i) => <SolutionItem key={i} label={d.label} value={d.value} />)}
                </div>
                <div>
                    <Button label={btnSection.label} value={btnSection.value} backgroundColor={'var(--white)'} color={'var(--black)'} />
                </div>
            </section>
        </>
    )
}

export default Solutions