import { GlobalContext } from '../../contexts/GlobalContext'
import { useContext } from "react";
import styles from './Contact.module.css';

import Button from '../../components/Button/Button';
import PageTitle from '../../components/PageTitle/PageTitle';
import Input from '../../components/Input/Input';
import classNames from 'classnames';
import AnimatedCube from '../../components/AnimatedCube/AnimatedCube';

const Contact = () => {
    const { state } = useContext(GlobalContext);
    const language = state.language || 'pt-br';
    const {
        title,
        subtitle,
        btnSection,
        form
    } = require(`../../assets/languages/${language}/contact.json`);
    const {
        formName,
        formEmail,
        formSubject,
        formPhone,
        formMessage
    } = form

    return (
        <>
            <section className={classNames([styles['container-home'], 'mg-bot-100'])} id='contact'>
                <AnimatedCube sectionId={'#contact'} cssClass={styles.cube1} cubeImg={'cube-contact-1.png'} positionSide={'center'} />
                <AnimatedCube sectionId={'#contact'} cssClass={styles.cube2} cubeImg={'cube-contact-2.png'} positionSide={'right'} />
                <AnimatedCube sectionId={'#contact'} cssClass={styles.cube3} cubeImg={'cube-contact-3.png'} positionSide={'right'} />
                <AnimatedCube sectionId={'#contact'} cssClass={styles.cube4} cubeImg={'cube-contact-4.png'} positionSide={'center'} />
                <div className={styles.content} data-aos="fade-right" data-aos-duration="1000">
                    <div className={classNames([styles.center, 'mg-top-50 mg-bot-30'])}>
                        <PageTitle title={title} width={'300px'} backgroundColor={'var(--primary)'} color={'var(--black)'} isTag={true} />
                    </div>
                    <div className={styles.input} >
                        <Input
                            id={formName.id}
                            name={formName.name}
                            type={formName.type}
                            placeholder={formName.placeHolder}
                        />
                    </div>

                    <div className={styles.input} >
                        <Input
                            id={formEmail.id}
                            name={formEmail.name}
                            type={formEmail.type}
                            placeholder={formEmail.placeHolder}
                        />
                    </div>
                    <div className={styles.input}>
                        <Input
                            id={formSubject.id}
                            name={formSubject.name}
                            type={formSubject.type}
                            placeholder={formSubject.placeHolder}
                        />
                    </div>
                    <div className={styles.input}>
                        <Input
                            id={formPhone.id}
                            name={formPhone.name}
                            type={formPhone.type}
                            placeholder={formPhone.placeHolder}
                        />
                    </div>
                    <Input
                        id={formMessage.id}
                        name={formMessage.name}
                        type={formMessage.type}
                        placeholder={formMessage.placeHolder}
                    />

                    <div className={classNames([styles.center, 'mg-top-30'])}>
                        <div className={styles['btn-send-container']}>

                            <Button label={btnSection.label} value={btnSection.value}  padding={'5px'} />
                        </div>
                        <span className='t3 mg-top-20'>{subtitle}</span>
                    </div>
                </div>
                <div className={styles['img-container']} data-aos="fade-left" data-aos-duration="1000">
                    <div className={classNames(['bg-icon', styles['img-contact']])}>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact