/**
 * Componente PageTitle que retorna o titulo da aplicação.
 *
 * @param {string} title -titulo
 * @param {string} subtitle - value retorno da função
 * @param {boolean} isTag - troca para button como label
 * @param {string} width - value retorno da função
 * @param {string} backgroundColor - cor de fundo
 * @param {string} color - cor do texto
 */

import Button from "../Button/Button"

const PageTitle = ({ title, subtitle, isTag, width, backgroundColor, color }) => {

    return (
        <>
        <div style={{
            display: 'flex',
            flexDirection: 'column'
        }}
        data-aos="fade-left" data-aos-duration="800">

            {isTag ?
                (<div style={{
                    ...(width ? {maxWidth: width} : {})
                }}>
                    <Button label={title} backgroundColor={backgroundColor} color={color} isOnlyLabel={isTag}/>
                </div>) :
                <span dangerouslySetInnerHTML={{ __html: title }} className='t1' />
            }
            {subtitle && <span dangerouslySetInnerHTML={{ __html: subtitle }} className='t3 mg-top-30 mg-bot-50' />}
        </div>
           
        </>
    )
}

export default PageTitle