import { GlobalContext } from '../../contexts/GlobalContext'
import { useContext } from "react";
import styles from './Home.module.css';
import AnimatedServices from '../../components/AnimatedServices/AnimatedServices';
import Button from '../../components/Button/Button';
import PageTitle from '../../components/PageTitle/PageTitle';
import AnimatedCube from '../../components/AnimatedCube/AnimatedCube';

const Home = () => {
    const { state } = useContext(GlobalContext);
    const language = state.language || 'pt-br';
    const {
        title,
        subtitle,
        btnSection,
        services
    } = require(`../../assets/languages/${language}/home.json`);


    return (
        <>

            <section className={`${styles['container-home']} mg-top-100 mg-bot-100`} id='home'>
                <AnimatedCube sectionId={'#home'} cssClass={styles.cube1} cubeImg={'cube-home-1.png'} positionSide={'left'} />
                <AnimatedCube sectionId={'#home'} cssClass={styles.cube2} cubeImg={'cube-home-2.png'} positionSide={'right'} />

                <div className={styles.content} >
                    <div className={`bg-icon ${styles.logo} mg-bot-50`} data-aos="fade-right" data-aos-duration="800"></div>
                    <div className='mg-bot-50 w-100' data-aos="fade-left" data-aos-duration="800">
                        <AnimatedServices itens={services} />
                    </div>
                    <PageTitle title={title} subtitle={subtitle} />
                    <div data-aos="fade-right" data-aos-duration="800">
                        <Button label={btnSection.label} value={btnSection.value} />

                    </div>

                </div>

            </section>
        </>
    )
}

export default Home