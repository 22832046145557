import React, { useEffect, useRef } from "react";
import styles from './MenuMobile.module.css';

const MenuMobile = ({ itens, toggle, isOpen, activeSection }) => {
    const scrollY = useRef(0);
    const toggleMenu = () => toggle(!isOpen);

    useEffect(() => {
        const html = document.querySelector("html");
        if (isOpen) {

            html.style.overflow = 'hidden'
            // document.body.style.overflowY = 'hidden';
        } else {
            html.style.overflow = 'auto'
            document.body.style.overflowY = 'auto';
        }
        return () => {
            html.style.overflow = 'auto'
            document.body.style.overflowY = 'auto';
        };
    }, [isOpen]);

    return (
        <div>
            <div className={`${styles['menu-mobile']} ${isOpen ? styles.open : ''}`} onClick={toggleMenu}>
                <div className={styles['menu-mobile-burger']}></div>
            </div>

            <div className={`${styles['menu-mobile-overlay']} ${isOpen ? styles.open : ''}`}>
                <div className={styles['menu-container']}>
                    {itens.map((d, i) => <span key={i}>{d.label.replace('<br />', '')}</span>)}
                    {/* <span> - {activeSection}</span> */}
                </div>
            </div>
        </div>
    );
};

export default MenuMobile;
