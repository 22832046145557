/* Injetar cores no CSS como variável
 *
 * Importar o objeto ou constante de cores
 * em injectColors utilizar o setProperty para injetar no Css setStyle.setProperty("--nome var css", valor da cor);
 */
import {
    primary,
    white, 
    black
} from './colors'


const injectColors = () => {

    const setStyle = document.documentElement.style;

    // Primárias
    setStyle.setProperty("--primary", primary);
    // Branco
    setStyle.setProperty("--white", white);
    // Branco
    setStyle.setProperty("--black", black);

}

export default injectColors