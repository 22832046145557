/**
 * Componente Footer que retorna o footer da aplicação.
 */

import { useContext } from "react";
import { GlobalContext } from '../../contexts/GlobalContext';
import styles from './Footer.module.css';
import classNames from "classnames";

const FooterIten = ({ values, label }) => {
    return (
        <div className={styles['footer-iten']}>
            <span className={styles.label}>{label}</span>
            {values && values.map((d, i) => <span key={i} dangerouslySetInnerHTML={{ __html: d }} className={styles.value}></span>)}
        </div>
    );
}

const SocialIten = ({ src, icon }) => {
    return (
        <div className={classNames(['bg-icon', styles['social-iten']])} style={{
            backgroundImage: `url('${icon}')`
        }}>
            <a href=""></a>
        </div>
    );
}


const Footer = () => {
    const { state } = useContext(GlobalContext);
    const language = state.language || 'pt-br';
    const {
        itens,
        legend,
        social
    } = require(`../../assets/languages/${language}/footer.json`);

    return (
        <div className="app bg-black">
            <div className={classNames(['container', styles.container])}>
                <div className={styles['container-itens']}>
                    {itens.map((d, i) => <FooterIten values={d.values} label={d.label} key={i} />)}
                    <div className={styles['footer-iten']}>
                        <div className={classNames(['bg-icon', styles.logo])}>

                        </div>
                        <div className={styles['social-container']}>
                            {social.map((d, i) => <SocialIten key={i} icon={d.icon} />)}

                        </div>
                    </div>
                </div>
                <span className={styles.legend} dangerouslySetInnerHTML={{ __html: `${new Date().getFullYear()} ${legend}` }}></span>
            </div>
        </div>
    );
}

export default Footer