import { GlobalContext } from '../../contexts/GlobalContext'
import { useContext } from "react";
import styles from './WHY.module.css';
import PageTitle from '../../components/PageTitle/PageTitle';
import CardWHY from '../../components/CardWhy/CardWhy';
import AnimatedCube from '../../components/AnimatedCube/AnimatedCube';

const WHY = () => {
    const { state } = useContext(GlobalContext);
    const language = state.language || 'pt-br';
    const {
        title,
        subtitle,
        itens
    } = require(`../../assets/languages/${language}/WHY.json`);
    return (
        <>
            <section className={`${styles['container-home']} mg-top-100`} id='why-the-perspective'>
                <AnimatedCube sectionId={'#why-the-perspective'} cssClass={styles.cube1} cubeImg={'cube-why-1.png'} positionSide={'left'} />
                <AnimatedCube sectionId={'#why-the-perspective'} cssClass={styles.cube2} cubeImg={'cube-why-2.png'} positionSide={'right'} />
                <div className={styles.content}>

                    <PageTitle title={title} subtitle={subtitle} />
                    <div className={`${styles['card-container']} mg-bot-100`} data-aos="fade-right" data-aos-duration="800">
                        {itens.map((d, i) => <CardWHY
                            key={i}
                            label={d.label}
                            value={d.value}
                            valueBackgroundColor={d.valueBackgroundColor}
                            valueTextColor={d.valueTextColor}
                            labelBackgroundColor={d.labelBackgroundColor}
                            labelTextColor={d.labelTextColor}
                        />)}
                        <div className={`bg-icon logo ${styles.logo}`}></div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default WHY