/**
 * Componente Button que retorna o butão padrão da aplicação.
 *
 * @param {string} label - label para exibição
 * @param {string} value - value retorno da função
 * @param {string} width - value retorno da função
 * @param {boolean} isDisabled - desabilitar botao
 * @param {boolean} isOnlyLabel - transforma em somente label e desabilita botao
 * @param {string} backgroundColor - cor de fundo
 * @param {string} color - cor do texto
 * @param {string} padding - padding do bottão  
 */

import { useState } from 'react';
import styles from './Button.module.css';
import classNames from 'classnames';

const Button = ({ label, value, width, isDisabled, isOnlyLabel, backgroundColor, color, padding }) => {
    const [isHovered, setIsHovered] = useState(false);
    
    return (
        <button
            className={classNames(styles.button, { [`t1 ${styles['only-label']}`]: isOnlyLabel})}
            disabled={isDisabled || isOnlyLabel}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => console.log('oi')}
            style={{
                ...(width ? { width } : {}),
                ...(backgroundColor ? { backgroundColor: isHovered ? 'var(--primary)' : backgroundColor} : {}),
                ...(padding ? { padding } : {}),
            }}>
            <span
                className='t2'
                style={{
                    ...(color ? { color } : {}),
                }}
                dangerouslySetInnerHTML={{ __html: label }}
            ></span>
        </button>
    )
}

export default Button