/**
 * Componente Header que retorna o header da aplicação.
 *
 * @param {string} name - name do input
 * @param {string} id - id do input
 * @param {string} type - type do input
 * @param {string} value - value do input
 * @param {string} placeholder - placeholder do input
 * @param {string} disabled - disabled do input
 * @param {string} width - width do input
 */

import styles from './Input.module.css';
import classNames from 'classnames';

const Input = ({
    name,
    id,
    type,
    value,
    placeholder,
    disabled,
    width
}) => {
    const isTextArea = type === 'textarea'

    const Input = () => {
        return (
            <input
                className={styles.input}
                name={name}
                id={id}
                type={type}
                placeholder={placeholder}
                value={value}
                disabled={disabled}
            />
        )
    }

    const Textarea = () => {
        return (
            <textarea
                className={classNames([styles.input, styles.textarea])}
                name={name}
                id={id}
                type={type}
                placeholder={placeholder}
                value={value}
                disabled={disabled}
            />
        )
    }



    return (
        <div
            className={styles.container}
            style={{
                ...(width ? { width } : width)
            }}>

            {isTextArea ? <Textarea /> : <Input />}
        </div>
    )
}

export default Input