/**
 * Função para acessar o assets/images e retornar uma imagem
 *
 * @param {string} path - caminho dentro do assets/images
 * @param {string} imgName - nome da imagem com extenção
 * * exemplo de uso getImage('icons', down.png)
 */
const getImage = (path, imgName) => {
    let img = null;
    if (imgName) {
        try {
            img = require(`../assets/images${path && `/${path}`}/${imgName}`);
        } catch (err) {
            img = null;
        }
    }
    return img
}

export default getImage