/**
 * Componente CardDNA que retorna o card da sessão de DNA.
 *
 * @param {string} label - titulo
 * @param {string} value - descrição
 * @param {string} iconName - nome do icone com extenção
 */

import { useState } from 'react';
import getImage from '../../useFull/getImage';
import styles from './CardDNA.module.css';

const CardDNA = ({ label, value, iconName }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => setHovered(true);

  const handleMouseLeave = () => setHovered(false); 

  const iconToDisplay = hovered ? iconName.replace('.', '_w.') : iconName;

  return (
    <div
      className={styles['card-dna']}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`bg-icon ${styles['card-dna-icon']}`}
        style={{ backgroundImage: `url(${getImage('icons', iconToDisplay)})` }}
      ></div>

      <div className={styles['card-dna-txt']}>
        <b><span className='t1'>{label}</span></b>
        <span className='t4'>{value}</span>
      </div>
    </div>
  );
};

export default CardDNA;
