
import React, { createContext, useReducer, useCallback } from 'react';
import languages from '../lists/languages.json'
const path = window.location.pathname
const lastIndex = path.lastIndexOf('/');
const initialLang = path.slice(-3).length >= 3 && path.charAt(0) === '/' ? path.slice(lastIndex + 1) : ''
const GlobalContext = createContext();

const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE'
const SET_ACTIVE_SECTION = 'SET_ACTIVE_SECTION'


const initialState = {
    language: languages.map(({ value }) => value).includes(initialLang.toLocaleLowerCase()) ? initialLang : 'pt-br',
    activeSection: ''
};

const reducer = (state, action) => {
    switch (action.type) {
        case UPDATE_LANGUAGE:
            return { ...state, language: action.payload };
        case SET_ACTIVE_SECTION:
            return { ...state, activeSection: action.payload };
        default:
            return state;
    }
};

const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const updateLanguage = useCallback((lang) => {
        dispatch({ type: UPDATE_LANGUAGE, payload: lang });
    }, []);

    const setActiveSection = useCallback((activeSection) => {
        dispatch({ type: SET_ACTIVE_SECTION, payload: activeSection });
    }, []);

    return (
        <GlobalContext.Provider value={{
            state,
            updateLanguage,
            setActiveSection
        }}>
            {children}
        </GlobalContext.Provider>
    );
};

export { GlobalContext, GlobalProvider };
