import { GlobalContext } from '../../contexts/GlobalContext'
import { useContext } from "react";
import styles from './DNA.module.css';
import Button from '../../components/Button/Button';
import CardDNA from '../../components/CardDNA/CardDNA';
import PageTitle from '../../components/PageTitle/PageTitle';
import AnimatedCube from '../../components/AnimatedCube/AnimatedCube';

const DNA = () => {
    const { state } = useContext(GlobalContext);
    const language = state.language || 'pt-br';
    const {
        title,
        subtitle,
        btnSection,
        itens
    } = require(`../../assets/languages/${language}/DNA.json`);
    return (
        <>
            <section className={`${styles['container-home']} mg-bot-100`} id='dna'>
            <AnimatedCube sectionId={'#dna'} cssClass={styles.cube1} positionSide={'left'} cubeImg={'cube-dna-1.png'} />
                <div className={styles.content}>
                    <PageTitle title={title} subtitle={subtitle} />
                    <div className={`${styles['card-container']} mg-bot-50`} data-aos="fade-right" data-aos-duration="800">
                        {itens.map((d, i) => <CardDNA key={i} label={d.label} value={d.value} iconName={d.icon} />)}
                    </div>
                    <div>
                        <Button label={btnSection.label} value={btnSection.value} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default DNA