import Home from './pages/Home/Home.jsx';
import DNA from './pages/DNA/DNA.jsx';
import Solutions from './pages/Solutions/Solutions.jsx';
import WHY from './pages/WHY/WHY.jsx';
import Contact from './pages/Contact/Contact.jsx';
import Header from './components/Header/Header.jsx';
import Footer from './components/Footer/Footer.jsx';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    AOS.init();
}, []);
  
  return (
    <>
      <Header brandName={'Perspective'} />
      <div className='app'>
        <div className='container'>
          <Home />
          <DNA />
        </div>
      </div>

      <div className='app bg-black'>
        <div className='container'>
          <Solutions />
        </div>
      </div>

      <div className='app'>
        <div className='container'>
          <WHY />
          <Contact />
        </div>
      </div>

      <Footer />
    </>
  );
}

export default App;
