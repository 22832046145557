/**
 * Componente Header que retorna o header da aplicação.
 *
 * @param {string} brandName - Nome da marca para ficar ao lado do logo
 */

import { useContext, useState, useEffect } from "react";
import { GlobalContext } from '../../contexts/GlobalContext';
import styles from './Header.module.css';
import classNames from 'classnames';
import MenuMobile from "../MenuMobile/MenuMobile";

const HeaderIten = ({ value, label, activeSection, _callback }) => {
    const handleScroll = () => {
        const section = document.querySelector(value); // Seleciona a seção correspondente ao valor
        _callback(value)
        if (section) {
            const sectionPosition = section.getBoundingClientRect().top + window.scrollY; // Posição da seção em relação ao documento
            const offset = 100; // Ajuste a margem conforme necessário (em pixels)
            window.scrollTo({
                top: sectionPosition - offset,
                behavior: 'smooth'
            });
        }
    };
    return (
        <div
            onClick={handleScroll}
            className={classNames(styles['header-iten'], { [styles.active]: activeSection === value })}
        >
            <span dangerouslySetInnerHTML={{ __html: label }}></span>
        </div>
    );
}

const Header = ({ brandName }) => {
    const { state, setActiveSection } = useContext(GlobalContext);
    const [isVisible, setIsVisible] = useState(true);
    const [isOpen, setIsOpen] = useState(false);


    // const [activeSection, setActiveSection] = useState('#home'); // Estado para a seção ativa
    const language = state.language || 'pt-br';
    const itens = require(`../../assets/languages/${language}/header.json`);

    useEffect(() => {
        const sections = document.querySelectorAll('section[id]'); // Seleciona todas as seções com id

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // Quando uma seção estiver visível na viewport, atualiza o estado com o id da seção
                        // console.log('active', entry.target.id)
                        setActiveSection(`#${entry.target.id}`);
                        if (entry.target.id === 'home') {
                            setTimeout(() => setIsVisible(false), 0); // Adiciona um atraso
                        } else {
                            setIsVisible(true);
                        }
                    }
                });
            },
            { threshold: 0.5 } // Define o threshold para 50% da seção visível
        );

        sections.forEach((section) => {
            observer.observe(section); // Observa cada seção
        });

        return () => {
            sections.forEach((section) => {
                observer.unobserve(section); // Limpa o observer quando o componente é desmontado
            });
        };
    }, []);

    const handleToggleMenu = () => setIsOpen(!isOpen)


    return (
        <>

            <div className={classNames(['app', styles['app-header']])}>
                <div className={classNames(['container', styles.header])}>
                    <div className={`${styles['container-logo']} ${!isVisible && !isOpen ? styles.hidden : styles.show}`}>
                        <div className={classNames(['bg-icon', styles.logo])} />
                        <span className={classNames([styles['logo-label'], 't3'])} >
                            {brandName}
                        </span>
                
                    </div>
                    {/* <span>{state.activeSection}</span>     */}
                    <div className={styles.hamb}>
                        <MenuMobile itens={itens} toggle={handleToggleMenu} isOpen={isOpen} activeSection={state.activeSection}/>
                    </div>


                    <div className={styles['container-itens']}>
                        {
                            itens.map((d) => (
                                <HeaderIten
                                    key={d.value}
                                    value={d.value}
                                    label={d.label}
                                    activeSection={state.activeSection}
                                    _callback={setActiveSection}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header